import { Card, Col, Container, Nav, Navbar, Row, Table } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import { DayItem } from "./components/DayItem.tsx";
import { Day } from "./types.ts";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import { content } from "./content.ts";

import sunflowerLogo from "./assets/sunflower-logo.png";

function App() {
  const { heading, intro, schedule, og } = content;

  const day1Url =
    "https://www.etix.com/ticket/p/55808249/the-sunflower-film-music-festival-paw-paw-the-lucky-wolf-theater";
  const day2Url =
    "https://www.etix.com/ticket/p/94386930/the-sunflower-film-music-festival-paw-paw-the-lucky-wolf-theater";
  const day3Url =
    "https://www.etix.com/ticket/p/91760998/the-sunflower-film-music-festival-paw-paw-the-lucky-wolf-theater";
  const packageUrl =
    "https://www.etix.com/ticket/k/15126236/the-sunflower-film-music-festival-3-day-package-paw-paw-the-lucky-wolf-theater";

  return (
    <div className="App">
      <MetaTags>
        <meta property="og:title" content={og.title} />
        <meta property="og:description" content={og.description} />
      </MetaTags>
      <Navbar fixed="top" className="shadow">
        <Container className="d-flex justify-content-between">
          <Navbar.Brand>
            <h1 className="m-0">{heading}</h1>
          </Navbar.Brand>
          <Nav>
            <TicketLink
              url="https://theluckywolfpawpaw.com/events/category/series/the-sunflower-film-music-festival/the-lucky-wolf-theater/paw-paw-michigan/"
              text="Buy Tickets"
            />
          </Nav>
        </Container>
      </Navbar>
      <main>
        <Container>
          <Row>
            <Col className="logo-container text-center">
              <img
                src={sunflowerLogo}
                className="logo img-fluid mb-5"
                alt="Sunflower Film and Music Festival logo"
              />

              <h2 className="mb-5">June 14-16, 2024</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <p dangerouslySetInnerHTML={{ __html: intro }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="mb-4">{schedule.heading}</h2>
              {schedule.days.map((day: Day, index: number) => (
                <DayItem day={day} key={day.heading} index={index} />
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Buy Tickets</h2>
              <div className="py-4">
                <Card className="p-md-3 border-0 mb-4">
                  <Table className="tickets-table p-3">
                    <thead>
                      <tr>
                        <th className="ps-2">Day</th>
                        <th>General Price</th>
                        <th>Student/Senior Price</th>
                        <th>Purchase Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ps-2">Day 1: June 14, 2024</td>
                        <td>Free</td>
                        <td>Free</td>
                        <td>
                          <TicketLink
                            url={day1Url}
                            text="Day 1 Tickets (FREE)"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-2">Day 2: June 15, 2024</td>
                        <td>$20</td>
                        <td>$10</td>
                        <td>
                          <TicketLink url={day2Url} text="Buy Day 2 Tickets" />
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-2">Day 3: June 16, 2024</td>
                        <td>$20</td>
                        <td>$10</td>
                        <td>
                          <TicketLink url={day3Url} text="Buy Day 3 Tickets" />
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-2">3-Day Package</td>
                        <td>$35</td>
                        <td>$15</td>
                        <td>
                          <TicketLink
                            url={packageUrl}
                            text="Buy 3-Day Package Tickets"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <footer className="bg-dark text-light">
        <Container className="py-4">
          <Row>
            <Col>
              &copy; 2024{" "}
              <a
                href="https://theluckywolfpawpaw.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                The Lucky Wolf
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;

const TicketLink: React.FC<{ url: string; text: string }> = ({ url, text }) => {
  return (
    <a
      className="btn btn-primary"
      href={url}
      role="button"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

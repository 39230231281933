import { Event } from "../types.ts";
import { CurlyBrackets } from "./CurlyBrackets.tsx";
import { SquareBrackets } from "./SquareBrackets.tsx";

export const ModalContent: React.FC<{ event: Event }> = ({ event }) => {
  const {
    maker,
    instagram,
    trailer,
    description,
    squareBrackets,
    curlyBrackets,
    genre,
  } = event;
  return (
    <>
      {squareBrackets && <SquareBrackets text={squareBrackets} />}
      {curlyBrackets && <CurlyBrackets text={curlyBrackets} />}

      {maker && <p>{maker}</p>}

      <div className="d-flex links">
        {instagram && (
          <a className="pe-4 mb-4" href={instagram}>
            [Instagram]
          </a>
        )}
        {trailer && (
          <a className="pe-4 mb-4" href={trailer}>
            [Trailer]
          </a>
        )}
      </div>

      {description && (
        <>
          <h5 className="my-4">Description</h5>
          <div
            dangerouslySetInnerHTML={{
              __html: description as string,
            }}
          />
        </>
      )}

      {genre && (
        <p>
          <strong>Genre:</strong> {genre}
        </p>
      )}
    </>
  );
};

import { Modal } from "react-bootstrap";
import { Event } from "../types.ts";

export const ModalHeader: React.FC<{ event: Event }> = ({ event }) => {
  const { title, runtime } = event;
  return (
    <Modal.Header closeButton className="py-4">
      <Modal.Title>
        <div className="d-flex align-items-bottom">
          <h4 className="mb-0 me-3">
            <strong>{title}</strong>{" "}
          </h4>
          <p className="ps-3 mb-0 me-3 flex-shrink-0">
            {runtime && <span className="runtime">({runtime})</span>}
          </p>
        </div>
      </Modal.Title>
    </Modal.Header>
  );
};

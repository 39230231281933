import { Modal } from "react-bootstrap";
import { Event } from "../types.ts";
import { ModalContent } from "./ModalContent";
import { ModalHeader } from "./ModalHeader.tsx";

export const TextModal: React.FC<{
  event: Event;
  show: boolean;
  handleClose(): void;
}> = ({ event, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <ModalHeader event={event} />

      <Modal.Body className="p-4">
        <ModalContent event={event} />
      </Modal.Body>
    </Modal>
  );
};

import React from "react";
import { Col, Row } from "react-bootstrap";
import { EventItem } from "./EventItem.tsx";
import { Day, Event } from "../types.ts";
import classNames from "classnames";

export const DayItem: React.FC<{ day: Day; index: number }> = ({
  day,
  index,
}) => {
  const { heading, events } = day;

  return (
    <div className={classNames("pb-5", `day-${index + 1}`)}>
      <Row>
        <h3 className="mb-4">{heading}</h3>
        <Col>
          {events.map((event: Event) => (
            <EventItem event={event} key={event.title} />
          ))}
        </Col>
      </Row>
    </div>
  );
};

import { Col, Modal, Row } from "react-bootstrap";
import { Event } from "../types.ts";
import { ModalContent } from "./ModalContent";
import { ModalHeader } from "./ModalHeader";

export const ImageModal: React.FC<{
  event: Event;
  show: boolean;
  handleClose(): void;
}> = ({ event, show, handleClose }) => {
  const { image, title } = event;
  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <ModalHeader event={event} />

      <Modal.Body className="p-4">
        <Row>
          <Col xs="12" md="6" lg="8">
            <ModalContent event={event} />
          </Col>

          <Col xs="12" md="6" lg="4">
            {image && (
              <img
                src={`/posters/${image}`}
                alt={`Screencap from ${title}`}
                className="ps-lg-4 w-100"
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

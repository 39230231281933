import React, { useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ImageModal } from "./ImageModal.tsx";
import { TextModal } from "./TextModal.tsx";
import { Event } from "../types.ts";
import classNames from "classnames";
import { SquareBrackets } from "./SquareBrackets.tsx";
import { CurlyBrackets } from "./CurlyBrackets.tsx";

export const EventItem: React.FC<{ event: Event }> = ({ event }) => {
  const {
    description,
    image,
    instagram,
    maker,
    runtime,
    squareBrackets,
    curlyBrackets,
    time,
    title,
    trailer,
  } = event;

  const [show, setShow] = useState(false);
  const hasModal = useMemo(
    () => description || image || instagram || runtime || trailer,
    [description, image, instagram, runtime, trailer]
  );

  const handleOpen = () => {
    if (hasModal) setShow(true);
  };
  const handleClose = () => setShow(false);

  const modalProps = {
    event,
    show,
    handleClose,
  };

  return (
    <Row
      className={classNames(
        "py-4 mx-1 mx-md-0 event-item",
        hasModal && "has-modal"
      )}
      onClick={handleOpen}
    >
      <Col md={2}>
        <p className="h4">{time}</p>
      </Col>
      <Col md={8} lg={9}>
        <div className="border-md-start ps-md-5">
          <div className="d-flex align-items-baseline">
            <h4>
              <strong>{title}</strong>{" "}
            </h4>
            <p className="ps-3 flex-shrink-0">
              {runtime && <span className="runtime">({runtime})</span>}
            </p>
          </div>

          {squareBrackets && <SquareBrackets text={squareBrackets} />}
          {curlyBrackets && <CurlyBrackets text={curlyBrackets} />}

          {maker && <p>{maker}</p>}
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          {image ? (
            <ImageModal {...modalProps} />
          ) : (
            <TextModal {...modalProps} />
          )}
        </div>
      </Col>
      <Col
        md="2"
        lg="1"
        className="d-flex align-items-center justify-content-center pt-4 pt-md-0"
      >
        {hasModal && <div className="plus-sign flex-shrink-0">+</div>}
      </Col>
    </Row>
  );
};

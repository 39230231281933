import { Content } from "./types.ts";
import sunflowerLogo from "./assets/sunflower-logo.png";

export const content: Content = {
  og: {
    title: "Sunflower Film and Music Festival: June 14th-16th, 2024",
    description:
      "Sunflowers are hyperaccumulators—plants that have adapted to thrive in environments with high concentrations of toxic materials. Sunflower Fest celebrates creativity’s ability to flourish and give back truth and beauty, even as it absorbs and faces up to the destructive elements of the cultures from which it arises. Through Sunflower Film Fest, we continue to honor India Summer Deibler-Love’s mission and vision of creating inclusive, nurturing spaces that bring community of all walks of life together for positive experience through art, music, and great coffee & wine.",
    image: sunflowerLogo,
  },
  heading: "Sunflower Film & Music Festival",
  intro: `
    <p>Sunflowers are hyperaccumulators—plants that have adapted to thrive in environments with high concentrations of toxic materials.</p>
    <p>Sunflower Fest celebrates creativity’s ability to flourish and give back truth and beauty, even as it absorbs and faces up to the destructive elements of the cultures from which it arises.</p>
    <p>Through Sunflower Film Fest, we continue to honor India Summer Deibler-Love’s mission and vision of creating inclusive, nurturing spaces that bring community of all walks of life together for positive experience through art, music, and great coffee & wine.</p>`,

  schedule: {
    heading: "FESTIVAL SCHEDULE",
    days: [
      {
        heading: "Friday, June 14th",
        events: [
          {
            time: "5 PM",
            title: "Cocktail/mocktail reception",
          },
          {
            time: "6 PM",
            title: "Silian perform",
            image: "silian.jpg",
            instagram: "https://www.instagram.com/silian_the_cool_band",
            description:
              "Silian are a Paw Paw–based 6-piece band. Basically the Grateful Dead if they were a bunch of Midwest teenagers.",
          },
          {
            time: "7 PM",
            title: "Last Words",
            curlyBrackets: "Entrant, Kazoo 48 Hour Film Fest",
            runtime: "6m",
            maker: "Directed by Cully Cooper",
            description: `
              <p>Detectives Barbosa and Lee track down a mysterious literary larcener.</p>
              <p>Created by Team Not Shy for Kazoo48, a Kalamazoo-based film festival in which filmmakers are given unique prompts and 48 hours to create a short film.</p>
              <h6>The prompts</h6>
              <p>
                <strong>Genre:</strong> Comedy<br/>
                <strong>Prop:</strong> Shovel<br/>
                <strong>Location:</strong> Park Bench
              </p>
            `,
            image: "last-words.jpg",
          },
          {
            time: "7:15 PM",
            title: "Motherboard Loves You",
            curlyBrackets:
              "Winner Best Amateur Picture, Kazoo 48 Hour Film Fest",
            runtime: "6m",
            maker: "Directed by Davis Henderson & Lorelei Moxon",
            description: `
              <p>Created in 48 hours, <em>Motherboard Loves You</em> follows Ether and Nettie as they try to escape an underground dystopia ruled by the mysterious Motherboard.</p>`,
            genre: "Sci-Fi/Fantasy",
            instagram: "https://www.instagram.com/davisdiscombobulatedvo",
            image: "motherboard.png",
          },
          {
            time: "7:30 PM",
            title: "Jaxon Curry: A Silly Little Guy",
            curlyBrackets: "World Premiere",
            runtime: "1h",
            maker: "Filmed and Edited by Mystic 8 Studios",
            description: `
              <p>Alternative comedian Jaxon Curry razzles and dazzles in a comedy special that combines a taping of the sold out live show, sketches, standup, and general silliness. Jaxon Curry × Mystic 8. Filmed at the Lucky Wolf.`,
            genre: "Comedy",
            instagram: "https://www.instagram.com/mystic8studios",
            image: "silly-little-guy.jpg",
          },
        ],
      },
      {
        heading: "Saturday, June 15th",
        events: [
          {
            time: "11 AM",
            title: "Esmeralda",
            runtime: "18m",
            maker: "Directed by Jennifer Greco",
            description: `
              <p>When a young woman returns to her native land to confront her mother about a traumatic childhood memory, the two women forge a newfound bond through a shared experience.</p>`,
            image: "esmeralda.jpg",
            instagram: "https://www.instagram.com/jennifergreco_",
            trailer: "https://www.youtube.com/watch?v=dSlWl3d7sMM",
            genre: "Drama",
          },
          {
            time: "11:30 AM",
            title: "Once Was",
            runtime: "11m",
            maker: "Directed by Lilan Bowden",
            description: `
              <p>Having experienced a tumultuous history as a couple, Kate and Zo reunite a year after their breakup. We learn about their history as a couple, brushing up against childhood trauma and the struggle of finding identity as an immigrant in a new country. Their reunion reveals the unavoidable truth: Although their timing was always off—their love was not in vain.</p>`,
            image: "once-was.jpg",
            instagram: "https://www.instagram.com/yourfriendlilan",
            genre: "Drama",
          },
          {
            time: "12 PM",
            title: "Inside the Beauty Bubble",
            runtime: "31m",
            maker: "Directed by Cheryl Bookout & Cheri Gaulke",
            curlyBrackets:
              "Winner of 31 awards on the 2022–2023 festival circuit",
            description: `
              <p>Inside the Beauty Bubble is a short documentary film about a flamboyant and renowned collector of hair artifacts fighting to keep his desert dreams alive. The Beauty Bubble Salon & Museum in Joshua Tree is the magical and kitsch-filled brainchild of Jeff Hafler.</p>
              <p>Our film covers a year in the life of Jeff and his roadside attraction as they face a year of lockdowns, protests and massive societal change. It is a film about family, fabulousness and folk art, a film that reminds us it’s sometimes the strong people on the margins that hold a community together.</p>`,
            image: "beauty-bubble.png",
            instagram: "https://www.instagram.com/insidethebeautybubblefilm",
            trailer: "https://vimeo.com/703892004",
            genre: "Documentary",
          },
          {
            time: "2 PM",
            title: "Diani & Devine Meet the Apocalypse",
            runtime: "1h 30m",
            maker: "Directed by Etta Devine & Gabriel Diani",
            description: `
              <p>A struggling comedy duo discovers that surviving the apocalypse is almost as difficult as surviving in Hollywood. When all power and communication systems mysteriously shut off, the unprepared couple pack up their things and hit the road in search of a safe haven to wait out the possible extinction event. Accompanied by their trusty dog Watson and their miserable cat Mrs. Peel, the duo must struggle against the mundane realities of the collapse of civilization while retaining their decency and rationality as the rest of humanity starts getting really, really weird.</p>`,
            image: "diani-and-devine.jpg",
            instagram: "https://www.instagram.com/etta_devine",
            trailer: "https://vimeo.com/142422326",
            genre: "Comedy",
          },
          {
            time: "5 PM",
            title: "Blind Ambition",
            runtime: "1h 36m",
            maker: "Directed by Warwick Ross & Robert Coe",
            squareBrackets:
              "With an introduction by Chris Robinson of Eagle Eye Wines",
            curlyBrackets:
              "Winner Best Documentary at Sydney Film Festival, Tribeca, Sonoma International Film Festival & Mendocino Film Festival",
            description: `
              <p>Four Zimbabwean men form their country's first Wine Tasting Olympics team.</p>`,
            instagram: "https://www.instagram.com/blindambitionfilm",
            image: "blind-ambition.jpg",
            trailer: "https://www.youtube.com/watch?v=8KDHmuNjj9k",
            genre: "Documentary",
          },
          {
            time: "7 PM",
            title: "Saturday after-party — food, drinks, vinyl, ☺️",
          },
        ],
      },
      {
        heading: "Sunday, June 16th",
        events: [
          {
            time: "11 AM",
            title: "The Situation perform",
            description:
              "The Situation is a duo of two of Kalamazoo's brightest up-and-coming young musicians, a dynamic blend of Julian’s slick pop/rock sensibilities and Jude’s jazz-influenced Americana.",
            instagram: "https://www.instagram.com/thesituationduoband",
            image: "situation.jpg",
          },
          {
            time: "2 PM",
            title: "The Concessionaires Must Die!",
            runtime: "1h 33m",
            maker: "Directed by America Young",
            description: `
              <p>On the brink of their beloved single-screen independent-movie theater being shut down forever, a misfit band of theater workers face the corporate evil, foreclosure, and the unthinkable: having to decide what they want to be when and if they ever grow up.</p>`,
            image: "concessionaires.jpg",
            instagram: "https://www.instagram.com/america_young",
            trailer: "https://vimeo.com/353311524",
            genre: "Comedy",
          },
          {
            time: "4 PM",
            title: "Punk Is Punk",
            runtime: "15m",
            maker: "Directed by Kimberly Bautista",
            description: `
              <p>A genderqueer punk rocker mom reluctantly agrees to organize a quinceañera for her daughter, setting the stage for her to face her estranged, traditional father.</p>`,
            instagram: "https://www.instagram.com/kimbidybautista",
            trailer: "https://vimeo.com/809427765",
            genre: "Drama",
            image: "punk-is.jpg",
          },
          {
            time: "4:30 PM",
            title: "Back to Lyla",
            runtime: "1h 30m",
            maker: "Directed by America Young",
            description: `
              <p>After discovering he might die soon, a timid young man and his estranged, outrageous childhood friends embark on a quest for closure on why the love of his life left.</p>`,
            image: "back-to-lyla.jpg",
            instagram: "https://www.instagram.com/america_young",
            trailer: "https://vimeo.com/719148943",
            genre: "Comedy • Romance",
          },
        ],
      },
    ],
  },
};
